import React from "react"

const ContnetsTitle = props => {
  const { title, subTitle } = props
  return (
    <>
      <h4 className="title e tx-center">{title}</h4>
      <p className="sub-title fw-b tx-center">{subTitle}</p>
    </>
  )
}
export default ContnetsTitle
