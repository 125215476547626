import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import * as FadeIn from "components/fadein"
import ContnetsTitle from "components/contentsTitle"
import { Link as ScrollLink } from "react-scroll"
import { Fv } from "components/fv"

const SelfLaundryPage = () => {
  const [navState, setNavState] = useState(1)
  const handleChangeNav = arg => {
    setNavState(arg)
  }
  const clickScroll = term => {
    const targetBtn = document.getElementById(`${term}`)
    targetBtn.click()
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryParams = new URLSearchParams(window.location.search)
      const term = queryParams.get("cont")
      if (term) {
        clickScroll(term)
      }
    }
  }, [])
  return (
    <Layout>
      <div className="self-laundry-page design-style">
        <Fv
          pcFv="https://balukoweb.blob.core.windows.net/images/self-laundry/page-mv.jpg"
          spFv="https://balukoweb.blob.core.windows.net/images/self-laundry/page-mv-sp.jpg"
          fvTitle="Self Laundry"
          color="white"
        />
        <div className="contents">
          <section className="about-self-laundry">
            <div className="about-self-laundry-contents">
              <FadeIn.Up>
                <h4 className="top-large-lead fw-b tx-center">
                  バルコはセルフランドリーのある生活を
                  <br />
                  提案します。
                </h4>
                <p className="sub-lead-text fw-b tx-center">
                  1時間で乾燥までできる便利さと、
                  <br className="sp" />
                  家庭用機器では実現できない上質な仕上がり。
                  <br />
                  地域の皆さまの毎日をより豊かにする
                  <br className="sp" />
                  セルフランドリーを目指しています。
                </p>

                <h4 className="merit tx-center">
                  セルフランドリーを選ぶメリット
                </h4>
              </FadeIn.Up>

              <div className="contents-item">
                <FadeIn.Up>
                  <h4 className="contents-item-title e tx-center">Wash</h4>

                  <div className="item-contents">
                    <div className="left-contents">
                      <img
                        src="https://balukoweb.blob.core.windows.net/images/self-laundry/self01.jpg"
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <div className="right-contents">
                      <div className="top-item">
                        <p className="item-header e fw-b">
                          Point <span className="e number fw-b">1</span>
                          <span className="title">
                            大きいものも、たくさんの洗濯物も早くて簡単
                          </span>
                        </p>
                        <div className="item-lead">
                          <p className="lead">
                            家庭の洗濯機では2回に分けて洗濯する量の洗濯物も一度に、洗濯から乾燥まで約1時間で完了します。
                            もちろん家庭では洗濯しにくい、羽毛ふとんや毛布なども大物も簡単に洗濯することができます。
                          </p>
                        </div>
                      </div>
                      <div className="bottom-item">
                        <p className="item-header e fw-b">
                          Point <span className="e number fw-b">2</span>
                          <span className="title">
                            洗濯物に合わせた洗剤・コースを選べる
                          </span>
                        </p>
                        <div className="item-lead">
                          <p className="lead">
                            洗濯物の性質や仕上がりの好みに合わせて、最適なコースを選ぶことができます。
                            洗剤と柔軟剤は自動で適正量が投入されるので、洗剤や柔軟剤を準備する必要はありません。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </FadeIn.Up>

                {/* <FadeIn.Up> */}
                <h4 className="contents-item-title mt50 e tx-center">Dry</h4>
                <div className="item-contents">
                  <div className="left-contents">
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/self02.jpg"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div className="right-contents">
                    <div className="top-item">
                      <p className="item-header e fw-b">
                        Point <span className="e number fw-b">3</span>
                        <span className="title">
                          ふんわり、ガス乾燥の特別な仕上がり
                        </span>
                      </p>
                      <div className="item-lead">
                        <p className="lead">
                          大きなドラムで大量の風を含ませながら洗濯物を乾燥します。タンブル乾燥※したタオルはパイルが立ち上がり、柔らかくふんわりと仕上がります。（ドラム内で回転しながら乾燥させること）
                        </p>
                      </div>
                    </div>
                    <div className="bottom-item">
                      <p className="item-header e fw-b">
                        Point <span className="e number fw-b">4</span>
                        <span className="title">
                          短時間で乾燥するから清潔、安心
                        </span>
                      </p>
                      <div className="item-lead">
                        <p className="lead">
                          ドラム内は50度～70度の設定された温度まで加熱し、短時間で乾燥させるため、生乾き臭の原因となるモラクセラ菌などの雑菌が繁殖しにくく、清潔です。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </FadeIn.Up> */}
              </div>
            </div>
          </section>

          <div className="page-mv-wrap">
            <div className="page-mv">
              <FadeIn.Up>
                <img
                  className="store-service-image"
                  src="https://balukoweb.blob.core.windows.net/images/self-laundry/self03.jpg"
                  height="auto"
                  alt=""
                  loading="lazy"
                />
              </FadeIn.Up>
            </div>
          </div>

          <section className="store-service">
            <FadeIn.Up>
              <div className="store-service-contents">
                <h4 className="about-store-title fw-b tx-center">
                  安心して、
                  <br />
                  快適にご利用いただける店舗
                </h4>

                <ul className="detail-list fw-b tx-center">
                  <li className="detail-list-item">
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/icon/check.svg"
                      alt=""
                    />
                    <p>店舗と機器を毎日清掃、メンテナンスしています。</p>
                  </li>
                  <li className="detail-list-item">
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/icon/check.svg"
                      alt=""
                    />
                    <p>店内は無料でご利用いただけるWi-Fi環境を備えています。</p>
                  </li>
                  <li className="detail-list-item">
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/icon/check.svg"
                      alt=""
                    />
                    <p>
                      除菌スプレー、除菌シートなどのアメニティーをご用意しています。
                    </p>
                  </li>
                  <li className="detail-list-item">
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/icon/check.svg"
                      alt=""
                    />
                    <p>店舗内は監視カメラで監視しています。</p>
                  </li>
                  <li className="detail-list-item">
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/icon/check.svg"
                      alt=""
                    />
                    <p>
                      お困りのときは、24時間コールセンターにお問い合わせいただけます。
                    </p>
                  </li>
                </ul>
                <div className="store-note tx-center">
                  <p>オンラインで機器の稼働状況を確認できます。</p>
                  <p>各店舗情報ページ、またはアプリからご確認いただけます。</p>
                  <p>
                    店舗情報ページは<Link to="/store-list">こちら</Link>
                  </p>
                </div>
              </div>
            </FadeIn.Up>
          </section>

          <section className="machines" id="machineSection">
            <FadeIn.Up>
              <div className="machines-contents">
                <ContnetsTitle title="Machines" subTitle="ランドリー機器" />

                <p className="note tx-center">
                  店舗によって機器（容量、料金設定）は異なります。
                </p>

                <div className="machine-detail">
                  <div className="left-contents">
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/text/pc/pc_wash_dryer.svg"
                      className="pc"
                      alt=""
                    />
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/text/sp/sp_wash_dryer.svg"
                      className="sp"
                      alt=""
                    />
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/wash_dryer.png"
                      className="washer-dryer"
                      alt=""
                    />
                  </div>
                  <div className="right-contents">
                    <p className="indication fw-b">価格と一回分の目安</p>

                    <div className="detail-table">
                      <dl className="detail-row">
                        <dt className="detail-col">所要時間</dt>
                        <dd className="detail-content sp-layout">
                          <div className="flex-item">
                            <dd className="head-col w-ten-col"></dd>
                            <dd className="head-col w-fourty-five-col">
                              <p className="tx-center fw-b">
                                洗濯と乾燥
                                <br />
                                約60分
                              </p>
                            </dd>
                            <dd className="head-col w-fourty-five-col">
                              <p className="tx-center fw-b">
                                洗濯のみ
                                <br />
                                約30分
                              </p>
                            </dd>
                          </div>
                        </dd>
                      </dl>

                      <dl className="detail-row">
                        <dt className="detail-col size-col fw-n">
                          <p className="tx-center fw-b">L</p>
                        </dt>
                        <dd className="detail-content">
                          <div className="flex-item under-line">
                            <dd className="detail-value w-ten-col">
                              <p className="tx-center">容量</p>
                            </dd>
                            <dd className="detail-value w-fourty-five-col">
                              <p className="tx-center fw-b">
                                16kg
                                <br />
                                <span className="fw-n">
                                  シングル毛布 約5枚分
                                </span>
                              </p>
                            </dd>
                            <dd className="detail-value w-fourty-five-col">
                              <p className="tx-center fw-b">
                                27kg
                                <br />
                                <span className="fw-n">
                                  シングル毛布 約9枚分
                                </span>
                              </p>
                            </dd>
                          </div>
                          <div className="flex-item">
                            <dd className="detail-value w-ten-col">
                              <p className="tx-center">料金</p>
                            </dd>
                            <dd className="detail-value w-fourty-five-col">
                              <p className="tx-center fw-b">¥1,400~</p>
                            </dd>
                            <dd className="detail-value w-fourty-five-col">
                              <p className="tx-center fw-b">¥1,000~</p>
                            </dd>
                          </div>
                        </dd>
                      </dl>

                      <dl className="detail-row">
                        <dt className="detail-col size-col mt3">
                          <p className="tx-center fw-b">M</p>
                        </dt>
                        <dd className="detail-content">
                          <div className="flex-item under-line">
                            <dd className="detail-value w-ten-col">
                              <p className="tx-center">容量</p>
                            </dd>
                            <dd className="detail-value w-fourty-five-col">
                              <p className="tx-center fw-b">
                                10kg
                                <br />
                                <span className="fw-n">
                                  シングル毛布 約3枚分
                                </span>
                              </p>
                            </dd>
                            <dd className="detail-value w-fourty-five-col">
                              <p className="tx-center fw-b">
                                17kg
                                <br />
                                <span className="fw-n">
                                  シングル毛布 約5枚分
                                </span>
                              </p>
                            </dd>
                          </div>
                          <div className="flex-item">
                            <dd className="detail-value w-ten-col">
                              <p className="tx-center">料金</p>
                            </dd>
                            <dd className="detail-value w-fourty-five-col">
                              <p className="tx-center fw-b">¥1,000~</p>
                            </dd>
                            <dd className="detail-value w-fourty-five-col">
                              <p className="tx-center fw-b">¥700~</p>
                            </dd>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="machine-detail">
                  <div className="left-contents">
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/text/pc/pc_dryer.svg"
                      className="pc"
                      alt=""
                    />
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/text/sp/sp_dryer.svg"
                      className="sp"
                      alt=""
                    />
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/dryer.png"
                      className="dryer"
                      alt=""
                    />
                  </div>
                  <div className="right-contents">
                    <p className="indication fw-b">価格と一回分の目安</p>

                    <div className="detail-table">
                      <dl className="detail-row">
                        <dt className="detail-col sp-dryer-layout">所要時間</dt>
                        <dd className="detail-content">
                          <div className="flex-item sp-layout">
                            <dd className="head-col w-twenty-five-col pc"></dd>
                            <dd className="head-col w-seventy-five-col pc">
                              <p className="fw-b">
                                衣類・毛布・ポリエステル綿の布団　30分〜40分
                                <br />
                                羽毛布団 <span className="text-line"></span>{" "}
                                50分
                              </p>
                            </dd>

                            <dd className="detail-content sp-dryer sp">
                              <div className="flex-item space-between under-line">
                                <dd className="detail-value">
                                  <p className="fw-b">
                                    衣類・毛布・
                                    <br />
                                    <span>ポリエステル綿の布団</span>
                                  </p>
                                </dd>
                                <dd className="detail-value">
                                  <p className="fw-b">30分〜40分</p>
                                </dd>
                              </div>
                              <div className="flex-item space-between">
                                <dd className="detail-value">
                                  <p className="fw-b">羽毛布団</p>
                                </dd>
                                <dd className="detail-value mr50">
                                  <p className="fw-b">50分</p>
                                </dd>
                              </div>
                            </dd>
                          </div>
                        </dd>
                      </dl>

                      <dl className="detail-row">
                        <dt className="detail-col size-col">
                          <p className="tx-center fw-b">L</p>
                        </dt>
                        <dd className="detail-content">
                          <div className="flex-item under-line">
                            <dd className="detail-value w-ten-col">
                              <p className="tx-center">容量</p>
                            </dd>
                            <dd className="detail-value w-full">
                              <p className="tx-center fw-b">
                                25kg
                                <br />
                                <span className="fw-n">
                                  シングル毛布 約6枚分
                                </span>
                              </p>
                            </dd>
                          </div>
                          <div className="flex-item">
                            <dd className="detail-value w-ten-col">
                              <p className="tx-center">料金</p>
                            </dd>
                            <dd className="detail-value w-full">
                              <p className="tx-center fw-b">8分/¥100~</p>
                            </dd>
                          </div>
                        </dd>
                      </dl>

                      <dl className="detail-row">
                        <dt className="detail-col size-col mt3">
                          <p className="tx-center fw-b">M</p>
                        </dt>
                        <dd className="detail-content">
                          <div className="flex-item under-line">
                            <dd className="detail-value w-ten-col">
                              <p className="tx-center">容量</p>
                            </dd>
                            <dd className="detail-value w-full">
                              <p className="tx-center fw-b">
                                14kg
                                <br />
                                <span className="fw-n">
                                  シングル毛布 約4枚分
                                </span>
                              </p>
                            </dd>
                          </div>
                          <div className="flex-item">
                            <dd className="detail-value w-ten-col">
                              <p className="tx-center">料金</p>
                            </dd>
                            <dd className="detail-value w-full">
                              <p className="tx-center fw-b">10分/¥100~</p>
                            </dd>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>

                <div className="machine-detail">
                  <div className="left-contents">
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/text/pc/pc_washer.svg"
                      className="pc"
                      alt=""
                    />
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/text/sp/sp_washer.svg"
                      className="sp"
                      alt=""
                    />
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/washer.png"
                      className="washer"
                      alt=""
                    />
                  </div>
                  <div className="right-contents">
                    <p className="indication fw-b">価格と一回分の目安</p>

                    <div className="detail-table">
                      <dl className="default-row">
                        <dt className="default-col w-thirty-col">
                          <p className="">所要時間</p>
                        </dt>
                        <dd className="default-col w-sixty-col">
                          <p className="tx-center fw-b">約40分~</p>
                        </dd>
                      </dl>
                      <dl className="default-row">
                        <dt className="default-col w-thirty-col">
                          <p className="">容量</p>
                        </dt>
                        <dd className="default-col w-sixty-col">
                          <p className="tx-center fw-b">
                            10kg
                            <br />
                            <span className="fw-n">シングル毛布 約3枚分</span>
                          </p>
                        </dd>
                      </dl>
                      <dl className="default-row">
                        <dt className="default-col w-thirty-col">
                          <p className="">料金</p>
                        </dt>
                        <dd className="default-col w-sixty-col">
                          <p className="tx-center fw-b">¥400~</p>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>

                <div className="machine-detail">
                  <div className="left-contents sneaker-laundry">
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/text/pc/pc_sneaker.svg"
                      className="pc"
                      width={162}
                      height={114}
                      alt=""
                    />
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/text/sp/sp_sneaker.svg"
                      className="sp"
                      alt=""
                    />
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/sneaker-laundry.png"
                      className="sneaker-laundry"
                      alt=""
                    />
                  </div>
                  <div className="right-contents sneaker-laundry">
                    <p className="indication fw-b">価格と一回分の目安</p>

                    <div className="detail-table">
                      <dl className="default-row">
                        <dt className="default-col w-thirty-col">
                          <p className="">所要時間</p>
                        </dt>
                        <dd className="default-col w-thirty-col">
                          <p className="tx-center fw-b">
                            洗濯
                            <br />
                            約20分
                          </p>
                        </dd>
                        <dd className="default-col w-fourty-col">
                          <p className="tx-center fw-b">
                            乾燥
                            <br />
                            約40分
                          </p>
                        </dd>
                      </dl>
                      <dl className="default-row">
                        <dt className="default-col w-thirty-col">
                          <p>容量</p>
                        </dt>
                        <dd className="default-col w-thirty-col">
                          <p className="tx-center fw-b">
                            2足
                            <br />
                            <span className="fw-n">子ども用の場合 4足</span>
                          </p>
                        </dd>
                        <dd className="default-col w-fourty-col">
                          <p className="tx-center fw-b">
                            2足
                            <br />
                            <span className="fw-n">子ども用の場合</span>
                          </p>
                        </dd>
                      </dl>
                      <dl className="default-row">
                        <dt className="default-col w-thirty-col">
                          <p className="">料金</p>
                        </dt>
                        <dd className="default-col w-thirty-col">
                          <p className="tx-center fw-b">¥200</p>
                        </dd>
                        <dd className="default-col w-fourty-col">
                          <p className="tx-center fw-b">20分/¥100</p>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </FadeIn.Up>
          </section>

          <section className="usage">
            <div className="usage-contents">
              <FadeIn.Up>
                <ContnetsTitle title="How to use" subTitle="基本の使い方" />
              </FadeIn.Up>

              <FadeIn.Up>
                <div className="usage-detail">
                  <ul className="nav-menu">
                    <li
                      className={`${
                        navState === 1 ? "selected" : ""
                      } nav-item tx-center fw-b`}
                    >
                      <button
                        className="nav-item-button"
                        onClick={() => handleChangeNav(1)}
                        onKeyDown={() => handleChangeNav(1)}
                      >
                        洗濯乾燥機
                      </button>
                    </li>
                    <li
                      className={`${
                        navState === 2 ? "selected" : ""
                      } nav-item tx-center fw-b`}
                    >
                      <button
                        className="nav-item-button"
                        onClick={() => handleChangeNav(2)}
                        onKeyDown={() => handleChangeNav(2)}
                      >
                        乾燥機
                      </button>
                    </li>
                  </ul>

                  <div className="usage-detail-contents">
                    {navState === 1 && (
                      <>
                        <div className="left-contents">
                          <p className="top-text fw-b">
                            洗濯乾燥機
                            <br />
                            使い方
                          </p>
                          <div className="image-wrapper">
                            <img
                              className="washer-dryer"
                              src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/wash_dryer.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="right-contents">
                          <dl className="flow-item">
                            <dt className="e step">1</dt>
                            <dd className="flow-detail">
                              <h4 className="flow-title">
                                ドラム内を確認します
                              </h4>
                              <p>
                                洗濯物を入れる前に一度ドラムを開けて、前のお客さまの忘れ物がないか確認します。
                              </p>
                            </dd>
                          </dl>
                          <dl className="flow-item">
                            <dt className="e step">2</dt>
                            <dd className="flow-detail">
                              <h4 className="flow-title">ドラム洗浄をします</h4>
                              <p>
                                お金を入れる前に、ドアを閉め機器のドラム洗浄ボタンを押すと、ドラム内を洗浄します。
                                <br />
                                <span>
                                  ※スニーカーランドリーや縦型洗濯機など一部の機器ではお金を入れてから槽洗浄ボタンを押します。
                                </span>
                              </p>
                            </dd>
                          </dl>
                          <dl className="flow-item">
                            <dt className="e step">3</dt>
                            <dd className="flow-detail">
                              <h4 className="flow-title">洗濯物を入れます</h4>
                              <p className="">
                                機器の適量ラインを目安に洗濯物を入れてドアを閉めます
                                <br />
                                <span>
                                  ※洗濯機、洗濯乾燥機、スニーカーウォッシャーには自動で洗剤（柔軟剤）が投入されます。
                                </span>
                              </p>
                            </dd>
                          </dl>
                          <dl className="flow-item">
                            <dt className="e step">4</dt>
                            <dd className="flow-detail">
                              <h4 className="flow-title">コースを選択します</h4>
                              <p>
                                機器のボタンで利用したいコースを選択します。
                                <br className="pc" />
                                洗濯機・洗濯乾燥機で柔軟剤を使用したくない場合は柔軟剤なしボタンを押します。
                                <br />
                                <span>
                                  ※一部の店舗では、集中決済端末やアプリからコースを選択することができます。
                                </span>
                              </p>
                            </dd>
                          </dl>
                          <dl className="flow-item">
                            <dt className="e step">5</dt>
                            <dd className="flow-detail">
                              <h4 className="flow-title">料金を支払います</h4>
                              <p>
                                機器にコインを投入します。
                                <br />
                                <span>
                                  ※機器はお釣りが出ません。店内の両替機で必要な小銭をご用意ください。
                                </span>
                                <br />
                                <span>
                                  ※一部の店舗では、集中決済端末やアプリでの決済やチャージ式カードで支払うことができます。
                                </span>
                              </p>
                            </dd>
                          </dl>
                        </div>
                      </>
                    )}
                    {navState === 2 && (
                      <>
                        <div className="left-contents">
                          <p className="top-text fw-b">
                            乾燥機
                            <br />
                            使い方
                          </p>
                          <div className="image-wrapper">
                            <img
                              className="dryer"
                              src="https://balukoweb.blob.core.windows.net/images/self-laundry/machines/dryer.png"
                              alt=""
                              width={99.83}
                              height={240}
                            />
                          </div>
                        </div>
                        <div className="right-contents dryer">
                          <dl className="flow-item">
                            <dt className="e step">1</dt>
                            <dd className="flow-detail">
                              <h4 className="flow-title">
                                ドラム内を確認します
                              </h4>
                              <p>
                                洗濯物を入れる前に一度ドラムを開けて、前のお客さまの忘れ物がないか確認します。
                              </p>
                            </dd>
                          </dl>
                          <dl className="flow-item">
                            <dt className="e step">2</dt>
                            <dd className="flow-detail">
                              <h4 className="flow-title">
                                ドラムリフレッシュをします
                              </h4>
                              <p>
                                お金を入れる前に、ドアを閉め機器のドラムリフレッシュボタンを押すと、ドラム内の空気を入れ替えます。
                              </p>
                            </dd>
                          </dl>
                          <dl className="flow-item">
                            <dt className="e step">3</dt>
                            <dd className="flow-detail">
                              <h4 className="flow-title">洗濯物を入れます</h4>
                              <p className="">
                                機器の適量ラインを目安に洗濯物を入れてドアを閉めます
                              </p>
                            </dd>
                          </dl>
                          <dl className="flow-item">
                            <dt className="e step">4</dt>
                            <dd className="flow-detail">
                              <h4 className="flow-title">
                                乾燥温度を選択します
                              </h4>
                              <p>
                                機器のボタンで利用したい乾燥温度を選択します。
                                <br />
                                <span>
                                  ※一部の店舗では、集中決済端末やアプリからコースを選択することができます。
                                </span>
                              </p>
                            </dd>
                          </dl>
                          <dl className="flow-item">
                            <dt className="e step">5</dt>
                            <dd className="flow-detail">
                              <h4 className="flow-title">料金を支払います</h4>
                              <p>
                                機器にコインを投入します。
                                <br />
                                <span>
                                  ※機器はお釣りが出ません。店内の両替機で必要な小銭をご用意ください。
                                </span>
                                <br />
                                <span>
                                  ※一部の店舗では、集中決済端末やアプリでの決済やチャージ式カードで支払うことができます。
                                </span>
                              </p>
                            </dd>
                          </dl>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </FadeIn.Up>
            </div>
          </section>

          <section className="course">
            <FadeIn.Up>
              <div className="course-contents">
                <ContnetsTitle
                  title="Wash & Dry Courses"
                  subTitle="洗濯乾燥コース"
                />
                <div className="course-detail">
                  <div className="course-detail-contents">
                    <div className="detail-left">
                      <h4 className="course-title fw-b">スタンダード</h4>
                      <p className="course-name">洗濯・乾燥</p>
                    </div>
                    <div className="detail-right">
                      <p className="soap-name">
                        洗剤・柔軟剤<span className="fw-b">peu</span>
                      </p>
                      <p className="detail">
                        洗浄力が高く、衣類に残りにくい洗剤と柔軟剤を使用した、日常の洗濯物に最適なコースです。
                      </p>
                    </div>
                  </div>
                  <div className="course-detail-contents">
                    <div className="detail-left">
                      <h4 className="course-title fw-b">ナチュラル</h4>
                      <p className="course-name">洗濯・低温乾燥</p>
                    </div>
                    <div className="detail-right">
                      <p className="soap-name">
                        洗剤・柔軟剤<span className="fw-b">SOMALI</span>
                      </p>
                      <p className="detail">
                        植物由来成分100%の洗濯用液体石けんを主成分とした洗剤とリンス剤を使用した、コットン素材の洗濯物に最適なコースです。低温で縮みを抑えて乾燥します。
                      </p>
                    </div>
                  </div>
                  <div className="course-detail-contents">
                    <div className="detail-left">
                      <h4 className="course-title fw-b">
                        羽毛ふとん
                        <br className="sp" />
                        専用
                      </h4>
                      <p className="course-name">洗濯・乾燥</p>
                    </div>
                    <div className="detail-right">
                      <p className="soap-name">
                        洗剤・柔軟剤<span className="fw-b">peu</span>
                      </p>
                      <p className="detail">
                        予洗いを追加し、最適な水流で洗う、羽毛ふとんの専用コースです。コース終了後、大型乾燥機に移して20分程度の追加乾燥してください。
                      </p>
                    </div>
                  </div>
                  <div className="course-detail-contents">
                    <div className="detail-left">
                      <h4 className="course-title fw-b">デリケート</h4>
                      <p className="course-name">洗濯・低温乾燥</p>
                    </div>
                    <div className="detail-right">
                      <p className="soap-name">
                        洗剤・柔軟剤<span className="fw-b">peu</span>
                      </p>
                      <p className="detail">
                        オリジナル洗剤・柔軟剤「peu」を使用し、やわらかい水流で洗うコースです。標準よりも低い温度で乾燥し、衣類へのダメージを軽減します。
                        <br />
                        <span>
                          ※一部店舗ではデリケートな衣類専用の洗剤を使用しています。
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </FadeIn.Up>
          </section>

          <section className="temperature" id="temperatureSection">
            <FadeIn.Up>
              <div className="temperature-contents">
                <ContnetsTitle title="Drying Temperature" subTitle="乾燥温度" />
                <div className="temperature-detail">
                  <div className="left-contents pc">
                    <div className="circle">
                      <div className="circle-contents">
                        <div className="top">
                          <img
                            src="https://balukoweb.blob.core.windows.net/images/self-laundry/temperature/mater-high.svg"
                            alt=""
                          />
                          <p className="fw-b">
                            標準/高温
                            <br />
                            <span className="fw-n">約70℃</span>
                          </p>
                        </div>
                        <div className="bottom">
                          <p>
                            しっかりと乾燥して清潔に保ちたいタオルやシーツ、羽毛ふとんは標準または高温がおすすめです。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-contents pc">
                    <div className="circle">
                      <div className="circle-contents">
                        <div className="top">
                          <img
                            src="https://balukoweb.blob.core.windows.net/images/self-laundry/temperature/mater-low.svg"
                            alt=""
                          />
                          <p className="fw-b">
                            中温/低温
                            <br />
                            <span className="fw-n">約55℃～60℃</span>
                          </p>
                        </div>
                        <div className="bottom">
                          <p>
                            縮みやすいコットン素材や熱に弱い化学繊維などは中温または低温がおすすめです。
                            <br />
                            <span>
                              ※機器によって、標準/高温、中温/低温など表記が異なります。
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="top-contents tx-center sp">
                    <div className="top-item">
                      <img
                        src="https://balukoweb.blob.core.windows.net/images/self-laundry/temperature/mater-high.svg"
                        alt=""
                        width={14.61}
                        height={42.43}
                      />
                      <p className="top-text tx-left fw-b">
                        標準/高温
                        <br />
                        <span className="fw-n">約70℃</span>
                      </p>
                    </div>

                    <p className="detail tx-left">
                      しっかりと乾燥して清潔に保ちたいタオルやシーツ、羽毛ふとんは標準または高温がおすすめです。
                    </p>
                  </div>
                  <div className="bottom-contents tx-center sp">
                    <div className="top-item">
                      <img
                        src="https://balukoweb.blob.core.windows.net/images/self-laundry/temperature/mater-low.svg"
                        alt=""
                        width={13.27}
                        height={42.43}
                      />
                      <p className="top-text tx-left fw-b">
                        中温/低温
                        <br />
                        <span className="fw-n">約55℃～60℃</span>
                      </p>
                    </div>

                    <p className="detail tx-left">
                      縮みやすいコットン素材や熱に弱い化学繊維などは中温または低温がおすすめです。
                      <br />
                      <span>
                        ※機器によって、標準/高温、中温/低温など表記が異なります。
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </FadeIn.Up>
          </section>

          <section className="washable" id="washableSection">
            <FadeIn.Up>
              <div className="washable-contents">
                <ContnetsTitle
                  title="Items you can wash"
                  subTitle="セルフランドリーで洗えるもの"
                />
                <div className="washable-detail">
                  <div className="washable-detail-contents">
                    <div className="detail-left">
                      <img
                        src="https://balukoweb.blob.core.windows.net/images/self-laundry/washable/washable01.svg"
                        alt=""
                      />
                    </div>
                    <div className="detail-right">
                      <p className="fw-b item-name">
                        羽毛ふとん／ポリエステルわたのふとん
                        <span>（キルティング加工されたもの）</span>
                      </p>
                      <p className="item-detail">
                        カバーを外して布地の破れや弱りがないか確認し、羽毛ふとん専用コース、またはスタンダードコースを使用してください。
                      </p>
                      <p className="note">
                        ※ウールや綿わたの布団は洗えません。
                      </p>
                    </div>
                  </div>
                  <div className="washable-detail-contents">
                    <div className="detail-left">
                      <img
                        src="https://balukoweb.blob.core.windows.net/images/self-laundry/washable/washable02.svg"
                        alt=""
                      />
                    </div>
                    <div className="detail-right">
                      <p className="fw-b item-name">
                        毛布<span>（アクリル毛布）</span>
                      </p>

                      <p className="note">
                        ※ウール、シルク、キャメルなど獣毛素材のものは洗えません。
                      </p>
                    </div>
                  </div>
                  <div className="washable-detail-contents">
                    <div className="detail-left">
                      <img
                        src="https://balukoweb.blob.core.windows.net/images/self-laundry/washable/washable03.svg"
                        alt=""
                      />
                    </div>
                    <div className="detail-right">
                      <p className="fw-b item-name">ダウンジャケット</p>

                      <p className="note">
                        ※キルティング加工されていないもの、ボンディング加工のもの（シームレスダウンなど）、防水加工や特殊なコーティングがされたもの、革の装飾のあるものなどは洗えません。
                      </p>
                    </div>
                  </div>
                  <div className="washable-detail-contents">
                    <div className="detail-left">
                      <img
                        src="https://balukoweb.blob.core.windows.net/images/self-laundry/washable/washable04.svg"
                        alt=""
                      />
                    </div>
                    <div className="detail-right">
                      <p className="fw-b item-name">カーペット</p>

                      <p className="note">
                        ※裏地にゴムや接着剤を使用したもの、毛足の長いものは洗えません。
                      </p>
                    </div>
                  </div>
                  <div className="washable-detail-contents">
                    <div className="detail-left">
                      <img
                        src="https://balukoweb.blob.core.windows.net/images/self-laundry/washable/washable05.svg"
                        alt=""
                      />
                    </div>
                    <div className="detail-right">
                      <p className="fw-b item-name">
                        カーテン<span>（金具類をすべて外す）</span>
                      </p>

                      <p className="note">
                        ※ネットに入れて洗濯をおすすめします。
                      </p>
                    </div>
                  </div>
                  <div className="washable-detail-contents">
                    <div className="detail-left">
                      <img
                        src="https://balukoweb.blob.core.windows.net/images/self-laundry/washable/washable06.svg"
                        alt=""
                      />
                    </div>
                    <div className="detail-right">
                      <p className="fw-b item-name">スニーカー</p>
                      <p className="item-detail">
                        専用のスニーカーランドリーを使用してください。
                      </p>
                    </div>
                  </div>

                  <div className="washable-detail-contents">
                    <div className="detail-left">
                      <img
                        src="https://balukoweb.blob.core.windows.net/images/self-laundry/washable/washable07.svg"
                        alt=""
                      />
                    </div>
                    <div className="detail-right">
                      <p className="fw-b item-name">敷布団</p>
                      <p className="item-detail">
                        敷ふとん専用コース・専用乾燥機のある店舗でのみ洗えます。
                      </p>
                    </div>
                  </div>
                </div>
                <p className="note-text">
                  【注意】洗濯物の状態によって、破損、縮み、色落ち、移染、型崩れなどが起こる可能性があります。
                  洗濯表示タグを確認いただき、ご自身の判断でご利用ください。
                </p>

                <a
                  href="https://balukoweb.blob.core.windows.net/pdf/pdf_Futonlaundry.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-group tx-center"
                >
                  <span className="btn-text fw-b">
                    ふとんの洗い方<span>（PDF）</span>
                  </span>
                </a>
              </div>
            </FadeIn.Up>
          </section>
        </div>
        <div className="">
          <ScrollLink
            to="machineSection"
            offset={-80}
            id="machine"
            smooth
          ></ScrollLink>
          <ScrollLink
            to="washableSection"
            offset={-80}
            id="washable"
            smooth
          ></ScrollLink>
          <ScrollLink
            to="temperatureSection"
            offset={-80}
            id="temperature"
            smooth
          ></ScrollLink>
        </div>
      </div>
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title="Self Laundry － 毎日の「洗濯」が楽しくなる、新しいスタイルのランドリー。"
    description="バルコのセルフランドリーの使い方やコース、料金、洗えるもの洗えないものを詳しくご紹介。家庭用機器では実現ではできない上質な仕上がりと、清潔で心地よい空間を提供します。"
  />
)
export default SelfLaundryPage
